<template>
  <li>
    <span :class="['txt_monthly', { tc_bbb: isDisabled }]">{{ itemData.paymentMonth }}</span>
    <Input
      :value.sync="itemData.amount"
      :maxLength="200"
      :isDisabled="isDisabled"
      @focusout="onFocusout"
    />
  </li>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
export default {
  name: "DraftPurchasePaymentListLine",
  components: {
    Input,
  },
  props: {
    itemData: Object,
    isDisabled: Boolean,
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
